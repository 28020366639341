import React from 'react'
import { Link, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import styled, { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  body {
    background-color: rgb(0, 36, 71);
  }
  nav {
    background-color: #e1eaf2;
  }

  footer{
    color: #bacbda;
  }
`

const Div = styled.div`
  display: block;
  position: relative;
  margin-top: 1rem;
  overflow: hidden;
  color: #111;

  div {
    display: flex;
    z-index: 1;
    height: 100%;
    padding: 1rem;
    background: #e1eaf2;

    /* // background-color: #fff; */
  }

  a {
    display: block;
    width: 95%;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.125;
    word-wrap: break-word;
  }
`

const LayoutCatalog = styled.div`
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative;
  width: 100%;
  max-width: 78rem;
  margin: 0 auto;
  background-color: rgb(0, 36, 71);

  .catalog_wrapper {
    width: 100%;
  }

  ul {
    padding: 0;
    list-style-type: none;
  }
`

const Article = ({ node }) => {
  return (
    <Div>
      <div>
        <Link to={`/alushta/${node.slug}`}>{node.title}</Link>
        <span>{node.slug}</span>
      </div>
    </Div>
  )
}

const TagSection = styled.div`
  display: block;
  margin-top: 16px;
`

const Tag = styled.div`
  margin-bottom: 16px;
  display: inline-block;
  max-width: fit-content;
  margin-right: 16px;
  border-radius: 16px;
  padding: 4px 16px;
  background: #e1eaf2;
  color: #111;
  cursor: pointer;
`

function Alushta(props) {
  const { edges } = props?.data?.allContentfulAlushta

  const [posts, setPosts] = React.useState(edges)

  const onTagClick = (tagCity) => {
    const newPosts = edges.filter(({ node }) => {
      if (tagCity === 'all') {
        return true
      } else {
        if (node.city === tagCity) return true
      }
    })
    setPosts(newPosts)
  }

  const categoryEdges = edges
    .map(({ node }) => node.city)
    .filter((item, index, arr) => arr.indexOf(item) === index)

  return (
    <Layout location="/alushta">
      <GlobalStyle />
      <LayoutCatalog>
        <Helmet title="Объекты военно-мемориального наследия Республики Крым" />
        <div className="catalog_wrapper">
          {/* <TagSection>
            <Tag onClick={() => onTagClick('all')}>Все</Tag>
            {categoryEdges.map((city) => (
              <Tag onClick={() => onTagClick(city)} key={city}>
                {city}
              </Tag>
            ))}
          </TagSection> */}

          <ul>
            {posts
            .sort((a, b) => a.node.slug - b.node.slug)
            .map(({ node }) => {
              return (
                <li key={node.slug}>
                  <Article node={node} />
                </li>
              )
            })}
          </ul>
        </div>
      </LayoutCatalog>
    </Layout>
  )
}

export default Alushta

export const AlushtaQuery = graphql`
  query AlushtaQuery1 {
    allContentfulAlushta {
      edges {
        node {
          geo {
            lat
            lon
          }
          date
          city
          address
          slug
          title
          type
        }
      }
    }
  }
`
